@font-face {
  font-family: "TWK Lausanne Pan 300";
  src: url("/src/fonts/TWKLausannePan-300.woff2") format("woff2"),
    url("/src/fonts/TWKLausannePan-300.woff") format("woff"),
    url("/src/fonts/TWKLausannePan-300.otf") format("opentype"),
    url("/src/fonts/TWKLausannePan-300.ttf") format("truetype");
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: "TWK Lausanne Pan 400";
  src: url("/src/fonts/TWKLausannePan-400.woff2") format("woff2"),
    url("/src/fonts/TWKLausannePan-400.woff") format("woff"),
    url("/src/fonts/TWKLausannePan-400.otf") format("opentype"),
    url("/src/fonts/TWKLausannePan-400.ttf") format("truetype");
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: "TWK Lausanne Pan 500";
  src: url("/src/fonts/TWKLausannePan-500.woff2") format("woff2"),
    url("/src/fonts/TWKLausannePan-500.woff") format("woff"),
    url("/src/fonts/TWKLausannePan-500.otf") format("opentype"),
    url("/src/fonts/TWKLausannePan-500.ttf") format("truetype");
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: "TWK Lausanne Pan 550";
  src: url("/src/fonts/TWKLausannePan-550.woff2") format("woff2"),
    url("/src/fonts/TWKLausannePan-550.woff") format("woff"),
    url("/src/fonts/TWKLausannePan-550.otf") format("opentype"),
    url("/src/fonts/TWKLausannePan-550.ttf") format("truetype");
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: "TWK Lausanne Pan 700";
  src: url("/src/fonts/TWKLausannePan-700.woff2") format("woff2"),
    url("/src/fonts/TWKLausannePan-700.woff") format("woff"),
    url("/src/fonts/TWKLausannePan-700.otf") format("opentype"),
    url("/src/fonts/TWKLausannePan-700.ttf") format("truetype");
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}