@font-face {
  font-family: "TWK Lausanne Pan 300";
  src: url("TWKLausannePan-300.2b3f865b.woff2") format("woff2"), url("TWKLausannePan-300.34336e17.woff") format("woff"), url("TWKLausannePan-300.db09aaf6.otf") format("opentype"), url("TWKLausannePan-300.9728906c.ttf") format("truetype");
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: "TWK Lausanne Pan 400";
  src: url("TWKLausannePan-400.f2cd2d93.woff2") format("woff2"), url("TWKLausannePan-400.d8675352.woff") format("woff"), url("TWKLausannePan-400.9c5d61af.otf") format("opentype"), url("TWKLausannePan-400.e9aa2d39.ttf") format("truetype");
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: "TWK Lausanne Pan 500";
  src: url("TWKLausannePan-500.c9b65d16.woff2") format("woff2"), url("TWKLausannePan-500.d1871bfd.woff") format("woff"), url("TWKLausannePan-500.a952d22f.otf") format("opentype"), url("TWKLausannePan-500.32045ca1.ttf") format("truetype");
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: "TWK Lausanne Pan 550";
  src: url("TWKLausannePan-550.91c4ad81.woff2") format("woff2"), url("TWKLausannePan-550.7f1e4776.woff") format("woff"), url("TWKLausannePan-550.87cbc7f8.otf") format("opentype"), url("TWKLausannePan-550.20a26ace.ttf") format("truetype");
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: "TWK Lausanne Pan 700";
  src: url("TWKLausannePan-700.e8fa5e97.woff2") format("woff2"), url("TWKLausannePan-700.4a928e36.woff") format("woff"), url("TWKLausannePan-700.4f0da375.otf") format("opentype"), url("TWKLausannePan-700.21b6e2c4.ttf") format("truetype");
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

/*# sourceMappingURL=index.09575f38.css.map */
